import { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { isIOS, isSafari } from "react-device-detect";
import { Link, useLocation, useParams, Navigate } from "react-router-dom";
import Report from './Report';
import Error404 from './Error404';
import './Authorize.css';

function withLocation(Component) {
    return props => <Component {...props} location={useLocation()} />;
}
function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Authorize extends Component {

    constructor(props) {
        super(props);
        this.nextStep = this.nextStep.bind(this);
        this.authorizePosition = this.authorizePosition.bind(this);
        this.getCurrentLocation = this.getCurrentLocation.bind(this);
        this.authorizeCamera = this.authorizeCamera.bind(this);
        this.state = {
            step: props.location.state == null ? 0 :props.location.state.step,
            coords: props.location.state == null ? {} :props.location.state.coords,
            isChecking: false,
            isAuthorized: false,
            onError: false,
            errorMsg: ""
        };
    }
    nextStep() {
        let step = this.state.step;
        switch(step){
            default:
            case 0:
                step++;
                this.setState({step: step});
                break;
            case 1:
                this.checkAuthorizations();
                break;
        }
       
    }
    async checkAuthorizations() {
        this.setState({isChecking: true});
        this.authorizePosition();
        
    }
    authorizePosition(){
        const _this = this;
        if ( navigator.permissions && navigator.permissions.query) {
            //try permissions APIs first
            navigator.permissions.query({ name: 'geolocation' }).then(function(result) {
                // Will return ['granted', 'prompt', 'denied']
                const permission = result.state;
                if ( permission === 'granted' || permission === 'prompt' ) {
                    _this.getCurrentLocation();
                } else {
                    if (isIOS && isSafari) {

                        _this.setState({
                            isChecking: false,
                            onError: true,
                            errorMsg: <Trans>You must allow geolocation on your browser. Click on <a href={_this.props.t('AuthorizeAuthLocHelpIOS')} target="_blank" rel='noreferrer'>this link</a> to help you do so.</Trans>
                        });
                    } else {
                        _this.setState({
                            isChecking: false,
                            onError: true,
                            errorMsg: <Trans>You must allow geolocation on your browser. Click on <a href={_this.props.t('AuthorizeAuthLocHelpAndroid')} target="_blank" rel='noreferrer'>this link</a> to help you do so.</Trans>
                        });
                    }
                }
            });
        } else if (navigator.geolocation) {
            this.getCurrentLocation();
        } else {
            this.setState({
                isChecking: false,
                onError: true,
                errorMsg: this.props.t('AuthorizeAuthLocNotSupported')
            });
            return false;
        }
    }

    getCurrentLocation(){
        const options = {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0
        };
        navigator.geolocation.getCurrentPosition(
            (position) => {
                sessionStorage.setItem("lat", position.coords.latitude);
                sessionStorage.setItem("lng", position.coords.longitude);
                
               console.log(position);
                this.authorizeCamera();
            },
            (error) => {
                this.setState({
                    isChecking: false,
                    onError: true,
                    errorMsg: error.message //_this.props.t('AuthorizeAuthLocUnavailable')
                });
            },
            options
        );
    }

    authorizeCamera(){
        if (navigator.mediaDevices) {
            navigator.mediaDevices.getUserMedia({audio: false, video: true}).then((stream) => {
                stream.getTracks().forEach(function(track) {
                    track.stop();
                });
                let step = this.state.step;
                step++;
                this.setState({step: step});
                this.setState({isChecking: false});
            }).catch((err) => {
                if (isIOS && isSafari) {
                    this.setState({
                        isChecking: false,
                        onError: true,
                        errorMsg: <Trans>You must allow the camera on your browser. Click on <a href={this.props.t('AuthorizeAuthCamHelpIOS')} target="_blank" rel='noreferrer'>this link</a> to help you do so.</Trans>
                    });
                    return false;
                } else {
                    this.setState({
                        isChecking: false,
                        onError: true,
                        errorMsg: <Trans>You must allow the camera on your browser. Click on <a href={this.props.t('AuthorizeAuthCamHelpAndroid')} target="_blank" rel='noreferrer'>this link</a> to help you do so.</Trans>
                    });
                    return false;
                }
            })
        } else {
            return {
                success: false,
                message: this.props.t('AuthorizeAuthLocNotSupported')
            }
        }
    }

    render(){
        
        const onError = this.state.onError;
        const step = this.state.step;
        if (step === 0) {
            let params = this.props;
            console.log(params);
            let uuid = params.params.uuid.match(/([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/);
            console.log(uuid);
            if (!uuid) {
                return <Error404 />;
            }
            sessionStorage.setItem("uuid", uuid[0]);
        }
        const isChecking = this.state.isChecking;
        const steps = [
            {id:1,title:this.props.t('AuthorizeStep1Title'), text:this.props.t('AuthorizeStep1Text'), text2:this.props.t('AuthorizeStep1Text2')},
            {id:2,title:this.props.t('AuthorizeStep2Title'), text:this.props.t('AuthorizeStep2Text'), text2:this.props.t('AuthorizeStep2Text2')},
        ];
        if (step == 2) {
            return (
                <Navigate to="/report" replace={true} />
            );
        }
        let title = steps[step].title;
        let text = steps[step].text;
        let text2 = steps[step].text2;
        if (onError) {
            title = this.props.t('AuthorizeErrorTitle');
            text = this.state.errorMsg;
            text2 = '';
        }

        const buttonLabel = this.props.t('ButtonNext');
        const containerClassname = 'Authorize-slide step-' + step;
        if (!onError) {
            if (isChecking) {
                return (
                    <div>
                        <div className="Authorize" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/fond_blanc.svg'})` }}>
                            <main>
                                <div className='Authorize-sliders'>
                                    <div className={containerClassname}>
                                        <div className='content'>
                                            <div className='App-Loader'></div>
                                        </div>
                                    </div>
                                    <div className="flex_row">
                                        {steps.map((data, i) =>
                                        i===step ? (
                                            <span key={data.id} className='active'>.</span>
                                        ) : (
                                            <span key={data.id}>.</span>
                                        )
                                        )}
                                    </div>
                                    
                                </div>
                            </main>
                        </div>
                        <div className='Landscape'></div>
                    </div>
                );
            } else {
                return (
                    <div>
                        <div className="Authorize" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/fond_blanc.svg'})` }}>
                            <main>
                                <div className='Authorize-sliders'>
                                    <div className={containerClassname}>
                                        <div className='content'>
                                            <h1>{title}</h1>
                                            <p className="Authorize-text">{text}</p>
                                            {(text2 !== '')?
                                            <p className="Authorize-text">{text2}</p>
                                            :
                                            <span></span>
                                            }
                                        </div>
                                    </div>
                                    <div className="flex_row">
                                        {steps.map((data, i) =>
                                        i===step ? (
                                            <span key={data.id} className='active'>.</span>
                                        ) : (
                                            <span key={data.id}>.</span>
                                        )
                                        )}
                                    </div>
                                    <div className='navigate'>
                                        <button onClick={this.nextStep}>
                                            {buttonLabel}
                                        </button>
                                    </div>
                                </div>
                            </main>
                        </div>
                        <div className='Landscape'></div>
                    </div>
                );
            }
        } else {
            return (
                <div>
                    <div className="Authorize" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/fond_blanc.svg'})` }}>
                        <main>
                            <div className='Authorize-sliders'>
                                <div className={containerClassname}>
                                    <div className='content'>
                                        <h1>{title}</h1>
                                        <p className="Authorize-text">{text}</p>
                                        {(text2 !== '')?
                                           <p className="Authorize-text">{text2}</p>
                                        :
                                        <span></span>
                                        }
                                    </div>
                                </div>
                                <div className="flex_row">
                                    {steps.map((data, i) =>
                                    i===step ? (
                                        <span key={data.id} className='active'>.</span>
                                    ) : (
                                        <span key={data.id}>.</span>
                                    )
                                    )}
                                </div>
                            </div>
                        </main>
                    </div>
                    <div className='Landscape'></div>
                </div>
            );
        }
    }
}

export default withTranslation()(withParams(withLocation(Authorize)));
