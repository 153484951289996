import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Routes, Route} from "react-router-dom";
import Authorize from './Authorize';
import Error404 from './Error404';
import Report from './Report';


class App extends Component {
    render(){
       return (
            <Routes>
                <Route path="/:uuid" element={<Authorize />} />
                <Route path="/report" element={<Report />} />
                <Route path="*" element={<Error404 />} />
            </Routes>
        );
    }
}

export default withTranslation()(App);
